<!-- 每日 -->
<template>
  <div>
    <div class="title">
      <div class="title-text" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('推荐任务')}} : {{ total }}</div>
      <div class="title-img"
           @click="refresh"><img src="@/assets/bracelet_img/task/reload.png"></div>
    </div>
    <div class="list">
      <van-list v-model="loading"
                :finished="finished"
                :immediate-check="false"
                :offset="20"
                :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                :finished-text="$t('没有更多了')"
                @load="onLoad">
        <van-cell v-for="(item, index) in list"
                  :key="index">
          <div class="list-box">
            <div class="list-box-top">
              <div class="list-top-title">{{ item.name }}</div>
              <div class="list-top-text" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('描述')}} :</div>
              <div class="list-top-text">{{ item.desc }}</div>
            </div>
            <div class="list-box-bot">
              <div class="list-bot-text">
                <div :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('奖励')}} :</div>
                <div>+{{ item.money }} {{ item.money_type }}</div>
              </div>
              <div v-if="item.is_receive == 0" class="list-box-bnt"
                   @click="taskReceive(item.id)" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('接受')}}</div>
              <div v-else class="list-box-bnt" :class="{'vn-italic': $i18n.locale == 'vn'}" >{{$t('待完成')}}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getTasknewList, TaskReceiveApi } from "@/api/api";
import { mapState } from "vuex";
export default {
  data () {
    return {
      total: 0,
      page: 1,
      limit:10,
      list: [],
      loading: false,
      finished: false,
      timer: null,
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  props: {
    tabsIndex: {
      type: Number,
      default: function () {
        return 1
      }
    }
  },
  watch: {
    tabsIndex () {
      if (this.tabsIndex == 1) {
        this.waitForGlobal();
      }
    }
  },
  methods: {
    onLoad () {
      // 异步更新数据
      getTasknewList({
        type: this.tabsIndex,
        page: this.page,
        limit:this.limit
      }).then(res => {
        if (res.code == 0) {
          this.total = res.data.total
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          if (res.data.data.length < this.limit) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    refresh () {
      if (!this.loading) {
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
      }
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        if (!this.loading) {
          this.loading = true
          this.finished = false
          this.list = []
          this.page = 1
          this.onLoad();
        }
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 接受任务 */
    taskReceive (id) {
      let data = {
        id,
        type: 0
        //    type: this.tabsIndex
      }
      TaskReceiveApi(data).then(res => {
        if (res.code == 0) {
          this.$toast(res.msg);
          this.loading = true
          this.finished = false
          this.list = []
          this.page = 1
          this.onLoad()

        } else {
          this.$toast(res.msg);
        }
      }).catch(err => {
        this.$toast(err.msg);
      })
    }
  },
}
</script>
<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-text {
    font-family: REEJI-PinboGB-Flash;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .title-img {
    width: 22px;
    min-width: 22px;
    height: 22px;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

.list {
  width: 100%;
  height: 60vh;
  overflow: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */

  .list-box {
    padding: 1px 0 1px 0;
    color: #ffffff;
    line-height: 1;

    .list-box-top {
      .list-top-title {
        font-family: TsangerYuYangT-W03;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        line-height: 30px;
      }
      .list-top-text {
        font-family: TsangerYuYangT-W03;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        line-height: 18px;
      }
    }

    .list-box-bot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .list-bot-text {
        font-family: REEJI-PinboGB-Flash;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
      }

      .list-box-bnt {
        background-color: #a7f264;
        border-radius: 13px 3px 13px 3px;
        border: solid 2px rgba(0, 0, 0, 0.5);
        font-family: REEJI-PinboGB-Flash;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2b2b2b;
        padding: 6px 22px;
        cursor: pointer;
      }
    }
  }
}
.list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

/deep/ .van-cell {
  background-image: linear-gradient(
      237deg,
      rgba(118, 233, 214, 0.83) 0%,
      rgba(159, 174, 230, 0.83) 100%
    ),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  border-radius: 8px;
  margin: 7px 0 10px 0;
}
/deep/ .van-cell::after {
  border: 0;
}
</style>